@font-face {
  font-family: 'Mont';
  src: url('./assets/font/Mont-SemiBold.eot');
  src: local('Mont SemiBold'), local('Mont-SemiBold'),
      url('./assets/font/Mont-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./assets/font/Mont-SemiBold.woff2') format('woff2'),
      url('./assets/font/Mont-SemiBold.woff') format('woff'),
      url('./assets/font/Mont-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Cairo';
  src: local('Cairo SemiBold'), local('Cairo-SemiBold'),
      url('./assets/font/Cairo-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: bold;
}
@font-face {
  font-family: 'Cairo';
  src: local('Cairo Bold'), local('Cairo-Bold'),
      url('./assets/font/Cairo-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bolder;
}
@font-face {
  font-family: 'Cairo';
  src: local('Cairo Regular'), local('Cairo-Regular'),
      url('./assets/font/Cairo-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}